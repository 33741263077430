.revenue_pagination {
    margin-bottom: 0;
}

.my_invalid_feedback {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}
